export function loadScript(src, _id) {
  return new Promise(function(resolve, reject) {
    if (document.getElementById(_id)) return resolve()

    const s = document.createElement('script')
    s.src = src
    s.async = true
    s.id = _id
    s.onload = () => {
      if (document.getElementById(_id)) {
        s.remove()
      } else {
        s.id = _id
      }
      return resolve()
    }
    s.onerror = reject
    document.head.appendChild(s)
  })
}
