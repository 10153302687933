<template>
  <div class="cover-or-slideshow"
      :class="{
        'cover--gutters': gutters
    }">
    <v-cover
      v-if="isDesktop"
      :src="cover"
      :ratio="coverRatio"
      :style="customStyles"
      :legend="legend"
      :media-link="mediaLink"
    />
    <v-slideshow
      v-else
      :slides="slides"
      :ratio="slidesRatio"
      :class="customClassesSlides"
      @onChangeSlide="onChangeSlide"
    />
    <transition name="fade">
      <p v-show="legend && showLegend" :class="{ 'legend-right' : !legendLeft }" class="legend">{{ legend }}</p>
    </transition>
  </div>
</template>

<script>
import VCover from './VCover.vue'
import VSlideshow from './VSlideshow.vue'

export default {
  name: 'VCoverSlideshow',

  components: {
    VCover,
    VSlideshow
  },

  data() {
    return {
      showLegend: true
    }
  },

  props: {
    customStyles: {
      type: String,
      required: false
    },
    cover: {
      type: String,
      required: true
    },
    coverRatio: {
      type: Number,
      required: false
    },
    slides: {
      type: Array,
      required: true
    },
    slidesRatio: {
      type: Number,
      required: false
    },
    customClassesSlides : {
      type: String,
      required: false
    },
    legend: {
      type: [Boolean, String],
      default: '',
      required: false
    },
    legendLeft: {
      type: Boolean,
      default: true
    },
    gutters: {
      type: Boolean,
      default: false
    },
    showLegendPos: {
      type: Number,
      default: 0
    },
    mediaLink: {
      type: String,
      required: false
    }
  },

  mounted() {
    if(window.innerWidth < 768) {
      this.onChangeSlide(0)
    }
  },

  methods: {
    onChangeSlide( slide ) {
      if(window.innerWidth < 768) {
        if(slide === 0 && this.showLegendPos === 0) {
          this.showLegend = true
        }
        else if (slide === 1 && this.showLegendPos === 1) {
          this.showLegend = true
        }
        else {
          this.showLegend = false
        }
      }
    }
  },

  computed: {
    isDesktop() {
      return window.innerWidth >= 850
    }
  }
}
</script>
<style lang="sass">
  .cover-or-slideshow
    position: relative

    &--gutters
    padding: 0 var(--gutter)

  .bg-black
    background-color: black
  .contain
    img
      object-fit: contain

  .legend
    text-transform: uppercase
    color: white
    font-size: 11px
    position: absolute
    bottom: 30px
    z-index: 2
    left: var(--gutter)
    letter-spacing: -0.05em

    @media screen and (max-width: 767px)
      bottom: 38px

  .legend-right
    left: inherit
    right: var(--gutter)


  .fade-enter-active,
  .fade-leave-active
      transition: opacity .1s
  .fade-enter,
  .fade-leave-active
      opacity: 0
</style>
