var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"slideshow",class:{
  'slideshow--gutters': _vm.gutters,
  'slideshow--wrap': _vm.wrap,
  'slideshow--demi': _vm.demi,
  'slideshow--showmargin': _vm.showMargin,
},style:({ '--slides': _vm.numberOfSlides }),on:{"mousedown":function($event){$event.preventDefault();return _vm.swipeStart.apply(null, arguments)},"touchstart":_vm.swipeStart}},[_c('div',{ref:"wrapper",staticClass:"slideshow__wrapper",class:{ 'slideshow__wrapper--transition': _vm.swipeOrigin === null },style:({ '--translation': _vm.translation })},_vm._l((_vm.slides),function(slide,index){return _c('div',{key:index,staticClass:"slideshow__slide",style:({ '--slices': slide.slices || 1 })},[(slide.src)?_c('v-media',{staticClass:"slideshow__media",class:{ 'slideshow__media--interactive': !_vm.isActive },attrs:{"src":slide.src,"srcset":slide.srcset,"sizes":slide.sizes,"link":slide.link,"share":slide.share,"tags":slide.tags,"media-link":slide.mediaLink,"instance":slide.instance,"ratio":slide.ratio,"poster":slide.poster}}):_vm._e(),(_vm.gutters && slide.slices > 1)?_c('div',{staticClass:"slideshow__slices"},_vm._l((slide.slices - 1),function(index){return _c('div',{key:index,staticClass:"slideshow__slice"})}),0):_vm._e(),(slide.caption)?_c('p',{staticClass:"slideshow__caption"},[_vm._v(" "+_vm._s(slide.caption)+" ")]):_vm._e()],1)}),0),(_vm.bullets && _vm.swipable)?_c('div',{staticClass:"slideshow__bullets",class:{
    'slideshow__bullets--push': _vm.hasLinks,
  }},_vm._l((((_vm.numberOfSlides - _vm.viewport) * _vm.snapPoints + 1)),function(_,index){return _c('div',{key:index,staticClass:"slideshow__bullet",class:{
      'slideshow__bullet--active':
        index === -_vm.translation * _vm.viewport * _vm.snapPoints,
    }})}),0):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }