<template>
  <div class="main" :style="{ visibility: loading ? 'hidden' : '' }">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'VMain',

  props: {
    snapshot: {
      type: Boolean,
      default: true
    }
  },

  data() {
    return {
      loading: true
    }
  },

  created() {
    this.resize = this.resize.bind(this)
  },

  mounted() {
    addEventListener('resize', this.resize, { passive: true })
    this.resize()

    if (window.snapshot) {
      this.snapshot && window.snapshot()
    } else {
      this.loading = false
    }
  },

  beforeDestroy() {
    removeEventListener('resize', this.resize)
  },

  methods: {
    resize() {
      document.body.style.setProperty('--vh', `${document.documentElement.clientHeight / 100}px`)
    }
  }
}
</script>

<style lang="sass">
@import ../stylesheets/main

.main
  --vw: 1vw
  --vh: 1vh

  --gutter: 80px

  +large
    --gutter: 50px

  +medium
    --gutter: 40px

  +small
    --gutter: 30px

  font-family: 'HelveticaNeue', 'Arial', sans-serif
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale

  &, a
    color: #000
</style>
