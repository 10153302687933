<template>
  <div
    class="navigation"
    :class="{
      'navigation--open': open,
      'navigation--root': root,
      'navigation--not-root': !root,
      'navigation--secondary': secondary
    }"
  >
    <span
      v-if="!root"
      class="navigation__back"
      @click="$emit('back')"
    >
      Back
    </span>
    <span
      v-if="title"
      class="navigation__title"
    >
      {{ title }}
    </span>
    <ul class="navigation__list">
      <li
        v-for="(item, index) in items"
        class="navigation__item"
        :key="index"
      >
        <a
          v-if="item.href"
          class="navigation__link"
          :href="item.href"
          target="_blank"
          @click="toggle($event, index)"
          rel="noopener"
        >
          {{ item.label }}
        </a>
        <span
          v-else
          class="navigation__link"
          @click="toggle($event, index)"
        >
          {{ item.label }}
        </span>
        <v-navigation
          v-if="item.items"
          class="navigation__sub"
          :title="item.label"
          :items="item.items"
          :open="active === index"
          :depth="depth + 1"
          @back="active = -1"
        />
      </li>
    </ul>
  </div>
</template>

<script>
const SECONDARY_DEPTH = 2
const TRANSITION_DURATION = 300
const BREAKPOINT = 1023

export default {
  name: 'VNavigation',

  props: {
    items: {
      type: Array,
      required: true
    },
    open: {
      type: Boolean,
      default: true
    },
    depth: {
      type: Number,
      default: 0
    },
    title: {
      type: String,
      default: ''
    }
  },

  watch: {
    open(open) {
      if (!open) {
        this.active = -1
      }

      this.resize()
    }
  },

  computed: {
    root() {
      return this.depth === 0
    },

    secondary() {
      return this.depth >= SECONDARY_DEPTH
    }
  },

  data() {
    return {
      active: -1
    }
  },

  created() {
    this.resize = this.resize.bind(this)
    this.resizeTimeout = null
    this.transitionTimeout = null
  },

  mounted() {
    addEventListener('resize', this.resize)
    this.resize(true)
  },

  beforeDestroy() {
    removeEventListener('resize', this.resize)
    clearTimeout(this.resizeTimeout)
    clearTimeout(this.transitionTimeout)
  },

  methods: {
    toggle(event, index) {
      if (!this.items[index].items) {
        return
      }

      this.active = this.active === index ? -1 : index

      event.preventDefault()
    },

    resize(event) {
      clearTimeout(this.resizeTimeout)
      clearTimeout(this.transitionTimeout)

      if (event || window.innerWidth <= BREAKPOINT) {
        this.$el.style.height = ''

        if (event) {
          this.$el.style.transition = 'none'
          this.transitionTimeout = setTimeout(() => {
            this.$el.style.transition = ''
          }, TRANSITION_DURATION)
        } else {
          this.$el.style.transition = ''
        }
      } else if (this.open) {
        this.$el.style.height = this.$el.scrollHeight + 'px'
        this.resizeTimeout = setTimeout(() => {
          this.$el.style.height = ''
        }, TRANSITION_DURATION)
      } else {
        this.$el.style.height = this.$el.scrollHeight + 'px'
        this.resizeTimeout = setTimeout(() => {
          this.$el.style.height = '0'
          this.resizeTimeout = setTimeout(() => {
            this.$el.style.height = ''
          }, TRANSITION_DURATION)
        }, 10)
      }
    }
  }
}
</script>

<style lang="sass">
.navigation
  visibility: hidden
  height: 0
  overflow: hidden
  line-height: 11px
  position: relative
  transition: height .3s ease-out

  &__back::before
    content: '\F109'
    width: 7px
    height: 6px
    top: 1px
    margin-left: -2px
    display: inline-block
    position: relative
    font:
      family: 'icons'
      size: 7px
      weight: normal

  &__link, &__back, &__title
    height: 11px
    font-size: 10px
    cursor: pointer
    color: #000
    user-select: none
    text:
      transform: uppercase
      decoration: none
    display: block

  &__back, &__title
    padding: 11px 20px 10px 20px
    display: none
    font-weight: bold

  &__title
    cursor: default

  &__item:last-of-type > &__sub > &__list
    padding-bottom: 0

  &__sub
    width: 100%

  &--open
    visibility: visible
    overflow: visible
    height: auto

  &--root
    width: 186px
    padding: 0 30px

  &--secondary
    text-align: right

  &--not-root &__list
    padding: 15px 0

  @media screen and (max-width: 1439px)
    &--root
      width: 154px
      padding: 0 20px

  @media screen and (max-width: 1023px)
    display: flex
    flex-direction: column
    z-index: 2

    &__back, &__title
      display: block
      flex:
        grow: 0
        shrink: 0

    &__link
      padding: 11px 20px

    &__list
      height: auto
      padding: 10px 0 0 0

    &__sub
      box-sizing: border-box
      padding: 8px 0 9px 0

    &--root
      padding: 0 0 8px 0

    &--root > &__list > &__item > &__link
      font-weight: bold

    &--not-root
      background-color: #fff
      top: 0
      position: absolute
      transform: translate3d(105%, 0, 0)
      transition: .5s cubic-bezier(0, 1, .5, 1)

    &--not-root &__list
      padding: 0
      overflow: auto

    &--open
      transform: translate3d(0, 0, 0)

    &--not-root, &--not-root#{&}--open
      height: calc(var(--vh, 1vh) * 100 - 56px)

    &--secondary
      text-align: left

  @media screen and (max-width: 767px)
    &__list
      padding-top: 9px

    &--not-root &__list
      padding-top: 1px

    &__link, &__back, &__title
      height: 9px
      font-size: 11px

    &__sub
      padding-top: 7px
</style>
