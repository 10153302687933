<template>
  <div
    class="dual"
    :class="{
      'dual--gutters': gutters,
    }"
  >
    <div class="dual__slide dual__slide__0">
      <v-caption>
        {{ caption }}
      </v-caption>
    </div>
    <div class="dual__slide dual__slide__1">
        <v-media
            class="cover__media"
            :src="slide.src"
            :lazy="slide.lazy"
            :srcset="slide.srcset"
            :sizes="slide.sizes"
            :ratio="slide.ratio"
            :media-link="slide.mediaLink"
        />
    </div>
  </div>
</template>

<script>
import VMedia from './VMedia.vue'
import VCaption from './VCaption.vue'
export default {
  name: 'VDual',
  components: {
    VMedia,
    VCaption
  },
  props: {
    slide: {
      type: Object,
      required: true
    },
    gutters: {
      type: Boolean,
      default: false
    },
    caption : {
      type: String,
      default: ''
    },
    mediaLink: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="sass">
.dual
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: var(--gutter);
  grid-row-gap: 0px;

  &--gutters
    padding: 0 var(--gutter)

  &__slide

  &__slide__0
    display: flex
    flex-direction: row
    flex-wrap: wrap
    
</style>
