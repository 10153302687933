<template>
    <div class="space"></div>
</template>

<script>
export default {
  name: 'VSpace',
  props: {
    big: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="sass">
.space
  width: 100%
  height: var(--gutter)
</style>
