<template>
  <div
    class="dual"
  >
    <div v-for="(slide, i) in slides"  :key="i" :class="`dual__slide dual__slide__${i}`">
        <v-media
            class="cover__media"
            :src="slide.src"
            :lazy="slide.lazy"
            :srcset="slide.srcset"
            :sizes="slide.sizes"
            :ratio="slide.ratio"
            :media-link="slide.mediaLink"
        />
    </div>
  </div>
</template>

<script>
import VMedia from './VMedia.vue'
export default {
  name: 'VDuo',
  components: {
    VMedia
  },
  props: {
    slides: {
      type: [Array, Object],
      required: true
    },
    gutters: {
      type: Boolean,
      default: false
    },
    mediaLink: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="sass">
.dual
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 1.5625vw
  grid-row-gap: 0px;
  overflow-x: hidden
  width: 100vw
  box-sizing: border-box

  &__slide

  &__slide__0
    display: flex
    flex-direction: row
    flex-wrap: wrap
</style>
