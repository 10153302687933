<template>
  <v-main class="app">
    <div id="turn">
      <p>
        <strong>PLEASE ROTATE YOUR DEVICE</strong>
        <BR />
        THIS PAGE IS BEST VIEWED IN PORTRAIT ORIENTATION
      </p>
    </div>
    <v-header class="header-desktop" fixed />
    <div
      class="app-wrapper-container"
      :class="{'center': center, 'show': show}"
      :style="{
        height: heightWrapper + 'px',
        'min-height': heightWrapperMin + 'px',
      }"
    >
      <div
        class="app-wrapper"

        :style="{
          height: heightWrapper + 'px',
          'min-height': heightWrapperMin + 'px',
        }"
      >
        <header role="banner" data-container="header" class="banner">
          <div
            data-component="nav"
            id="mainnav"
            class="c-nav"
            nav-opened="false"
          >
            <div data-component="navigation" class="l-header l-header--light">
              <div class="l-header__nav">
                <div class="l-header__logo" data-ref="headerLogo">
                  <img
                    fetch-priority="high"
                    src="https://res.cloudinary.com/neuvieme-page/image/upload/v1666189495/thrill-me/01_YSL_Logo_SL-RiveDroite_1.svg"
                    style="118 / 24"
                    alt="ALLER SUR LA PAGE D’ACCUEIL SAINT LAURENT"
                  />
                </div>
              </div>
              <div class="l-header__aside" data-ref="headeraside">
                <a href="https://www.ysl.com/fr-fr/page?cid=rd-event-thrill-me">
                  <span
                    class="header__burger_desk header__burger_desk--close">
                </span>
                </a>
              </div>
            </div>
          </div>
        </header>
        <div class="iframe-wrapper">
        <a title="Logiciel billetterie en ligne"
            href="https://weezevent.com/?c=sys_widget"
            class="weezevent-widget-integration"
            data-src="https://widget.weezevent.com/ticket/E898015/?code=77071&locale=fr-FR&width_auto=1&color_primary=000000"
            data-width="650"
            data-height="600"
            data-id="898015"
            data-resize="1"
            data-width_auto="1"
            data-noscroll="0"
            data-use-container="yes"
            data-type="neo"
            target="_blank">Billetterie Weezevent</a>
          </div>
      </div>
    </div>
    <dic class="overlay"></dic>
    <div class="desktop-bg" @click="onRedirect()" :class="{'isMobile' : isMobile}">
      <img
        :src="!isMobile ? 'https://res.cloudinary.com/neuvieme-page/image/upload/f_auto,w_1600/v1666340113/thrill-me/stg-pre.ysl.com_en-fr_calendar_name_rd-event-halloween-films.html_macbook.png' : 'https://res.cloudinary.com/neuvieme-page/image/upload/f_auto,w_800/v1666340131/thrill-me/stg-pre.ysl.com_en-fr_calendar_name_rd-event-halloween-films.html_iPhone_XR.png'"
        :style="!isMobile ? 'aspect-ratio: 2880 / 1400;' : 'aspect-ratio: 828 / 1792;'"
        sizes="100vw"
        loading="eager"
        alt="placeholder"
      />
    </div>
  </v-main>
</template>

<script>
import { loadScript } from '../utils/loadScript'
export default {
  data() {
    return {
      heightWrapper: 0,
      heightWrapperMin: 0,
      show: false,
      center: false
    }
  },
  async mounted() {
    await loadScript('https://widget.weezevent.com/weez.js')
    this.heightWrapperMin = window.innerHeight - 60
    // this.show = true
    window.setInterval(() => {
      if (document.querySelector('iframe')) {
        this.heightWrapper = document.querySelector('iframe').offsetHeight + 88
        this.heightWrapperMin = document.querySelector('iframe').offsetHeight + 88
        this.center = this.heightWrapper < window.innerHeight
      }
    }, 16)
    window.setTimeout(() => {
      this.heightWrapperMin = window.innerHeight - 60
      this.show = true
    }, 500)
  },
  methods: {
    onRedirect() {
      if (window.innerWidth < 768) {
        return false
      }
      window.location.href = 'https://www.ysl.com/fr-fr/page?cid=rd-event-thrill-me'
    }
  },
  computed: {
    isMobile() {
      return window.innerWidth < 768
    }
  }
}
</script>

<style type="scss">
body {
  min-height: 100vh;
  /* mobile viewport bug fix */
  min-height: -webkit-fill-available;
}

html {
  height: -webkit-fill-available;
}
body {
  overflow: auto;
}

.center {
  position: absolute !important;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: 0 !important;
  padding-bottom: 0 !important;
}

.app-wrapper-container {
  width: 100%;
  position: relative;
  max-width: 410px;
  min-width: 340px;
  margin: auto;
  min-height: 540px;
  z-index: 10;
  margin: 25px auto;
  overflow: hidden;
  padding-bottom: 25px;
}

.app-wrapper {
  width: 100%;
  position: relative;
  min-width: 340px;
  max-width: 410px;
  margin: auto;
}

.app-wrapper-container  {
  opacity: 0;
}
.app-wrapper-container.show {
  opacity: 1;
  transition: opacity 0.1s linear;
}

.overlay {
  position: fixed;
    top: 0;
    left: 0;
    z-index: 4;
    background: rgba(0,0,0,0.5);
    width: 100vw;
    height: 100vh;
    backdrop-filter: blur(2px);
}

.hide-footer {
  height: 40px;
  position: absolute;
  bottom: 0;
  left: 50%;
  left: 50%;
  z-index: 5;
  background: white;
  width: 100%;
  transform: translateX(-50%);
  display: none;
}

.app-wrapper a {
  text-decoration: underline;
}

.banner {
  background: white;
  height: 88px;
}

.l-header {
  height: 56px;
  position: relative;
}

.l-header__nav {
  position: relative;
}

.l-header__logo {
  display: block;
}

.l-header__logo img {
    display: block;
    width: 100%;
    aspect-ratio: 118 / 24;
    max-width: 120px;
    margin: auto;
    height: 88px;
}

body {
  font-family: Helvetica_Regular, Arial, sans-serif;
}

iframe {
  width: 100%;
  /* overflow: hidden; */
}

.container {
  padding: 12px 20px;
  background: white;
  position: relative;
  z-index: 5;
  width: 285px;
}

.container p {
  margin: 0;
  width: 100%;
  position: relative;
  z-index: 2;
  text-transform: uppercase;
  font-family: Helvetica;
  font-size: 10px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: left;
}

.booking {
  position: relative;
  z-index: 1;
}

.desktop-bg {
  width: 100%;
  height: calc(100vh - 56px);
  position: fixed;
  top: 55px;
  left: 0;
  z-index: 3;
  background-color: #fff;
  display: block;
  cursor: pointer;
}

.desktop-bg img {
  width: 100%;
  height: 100vh;
  object-fit: contain;
  aspect-ratio: 2048 / 1280;
}
.desktop-bg.isMobile img {
  height: auto;
}

.header-desktop {
  z-index: 4;
}

.desktop-bg .overlay {
  background-color: #000;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 20;
  opacity: 0.1;
  cursor: pointer;
}

.header__burger_desk {
    /* padding: 0 7px; */
    margin: 0 auto 0 auto;
    cursor: pointer;
    color: rgba(0, 0, 0, 0.847);
    font-family: icons;
    font-size: 16px;
    position: absolute;
    top: 19px;
    /* transform: translateY(-50%); */
    right: 19px;
}

.iframe-wrapper a {

    display: none;

}

a {
  text-decoration: none;
}

.header__burger_desk--close:before {
  content: '\F113';
}

/* .header__navigation,
.header-desktop {
  display: none;
} */

.app-wrapper {
  width: 100%;
  max-width: 340px;
  z-index: 10;
  height: auto !important;
  background: #fff;
}

@media screen and (min-width: 768px) {
  .app-wrapper {
    max-width: 410px;
  }
  .desktop-bg {
    display: block;
  }
  .header-desktop,
  .header__navigation {
    display: block;
    pointer-events: none;
  }
  .app-wrapper-container {
    max-width: 410px;
  }
  .desktop-bg {
    height: 100vh;
    top: 0;
  }
}

#turn {
  text-transform: uppercase;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: white;
  z-index: 100;
  display: none;
  text-align: center;
  line-height: 14px;
}

#turn strong {
  font-weight: bold;
}

#turn p {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 10px;
}

@media only screen and (min-device-width: 350px) and (max-device-width: 1000px) and (min-aspect-ratio: 13/9) {
  #turn {
    display: block;
  }
}
</style>
