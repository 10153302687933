import { render, staticRenderFns } from "./VStripes.vue?vue&type=template&id=15e02b39&scoped=true&"
import script from "./VStripes.vue?vue&type=script&lang=js&"
export * from "./VStripes.vue?vue&type=script&lang=js&"
import style0 from "./VStripes.vue?vue&type=style&index=0&id=15e02b39&scoped=true&lang=sass&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "15e02b39",
  null
  
)

export default component.exports